<div class="clr-row clr-justify-content-center margin-0" style="margin-top: 60px; margin-bottom: 50px">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <ng-container *ngIf="!order">
      <app-loading-indicator></app-loading-indicator>
    </ng-container>

    <ng-container *ngIf="order">
      <div class="title-container">
        <div>
          <h2>
            {{__('heading')}}
          </h2>
          <div class="id37-text1">
            {{__('text1')}}<br><br>
            {{__('text2')}}<br>
            <a (click)="navigateToOrders()">{{order.orderId}}</a>.<br>
          </div>
        </div>
      </div>
      <ng-container *ngIf="newTest, else noTest">
        <div class="id37-text1">
          {{__('text3')}}
        </div>

        <button (click)="navigateToTest()" class="id37-btn-outline navigate-to-test-button">
          {{__('goToTest')}}
        </button>
      </ng-container>
      <ng-template #noTest>
        <div class="id37-text1">
          {{__('text4')}}
        </div>
        <button (click)="navigateToChatbot()" class="id37-btn-outline navigate-to-test-button">
          {{__('goToChatbotJay')}}
        </button>
      </ng-template>
    </ng-container>

  </div>
</div>
